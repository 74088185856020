var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.routeMetaTitle
          },
          on: {
            "go-back": _vm.goBack,
            "on-close": _vm.handleClosePage
          }
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "details"
        }, [_c('div', {
          staticClass: "title"
        }, [_vm._v("资产明细")]), _c('table', [_c('tr', [_c('td', [_vm._v("#")]), _c('td', [_vm._v("资产名称")]), _c('td', [_vm._v("主规格")]), _c('td', [_vm._v("数量")]), _c('td', [_vm._v("单位")]), _c('td', [_vm._v("此次入库数量")])]), _vm._l(_vm.detailList, function (item, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(item.productName))]), _c('td', [_vm._v(_vm._s(item.standard))]), _c('td', [_vm._v(_vm._s(item.inNumTotal))]), _c('td', [_vm._v(_vm._s(item.unit))]), _c('td', [_vm._v(_vm._s(item.inNum))])]);
        })], 2)]), _c('van-cell-group', {
          staticClass: "cell-group form",
          attrs: {
            "inset": ""
          }
        }, [_c('van-cell', {
          staticClass: "picker",
          attrs: {
            "title": "选择该批资产的进入仓库"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('div', {
                staticClass: "pickerText",
                class: {
                  disabled: _vm.spaceName === ''
                }
              }, [_vm._v(_vm._s(_vm.spaceName || '-'))])];
            },
            proxy: true
          }])
        }), _vm.sourceType == 1 ? _c('van-cell', {
          staticClass: "picker",
          attrs: {
            "title": "此次入库方式"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('div', {
                staticClass: "pickerText",
                class: {
                  disabled: _vm.stockMethodName === ''
                }
              }, [_vm._v(_vm._s(_vm.stockMethodName || '-'))])];
            },
            proxy: true
          }], null, false, 3041951534)
        }) : _vm._e(), _c('van-field', {
          attrs: {
            "label": "备注",
            "readonly": "",
            "placeholder": "-",
            "type": "textarea",
            "rows": "2",
            "autosize": "",
            "show-word-limit": "",
            "maxlength": "200"
          },
          model: {
            value: _vm.remark,
            callback: function ($$v) {
              _vm.remark = $$v;
            },
            expression: "remark"
          }
        }), _c('van-cell', {
          attrs: {
            "title": "入库人签字"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('div', {
                staticClass: "signPanel"
              }, [_c('img', {
                attrs: {
                  "src": _vm.stockUserSignUrl
                }
              })])];
            },
            proxy: true
          }])
        }), _c('van-cell', {
          attrs: {
            "title": "入库照片"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('div', {
                staticClass: "uploadPanel"
              }, [_c('van-uploader', {
                attrs: {
                  "show-upload": false,
                  "deletable": false
                },
                model: {
                  value: _vm.stockInImgList,
                  callback: function ($$v) {
                    _vm.stockInImgList = $$v;
                  },
                  expression: "stockInImgList"
                }
              })], 1)];
            },
            proxy: true
          }])
        })], 1), _vm.productList2.length ? _c('div', {
          staticClass: "summaryProducts"
        }, [_c('div', {
          staticClass: "title"
        }, [_vm._v("此次入库的固定资产编号")]), _c('div', {
          staticClass: "list2"
        }, [_c('table', [_c('tr', {
          staticClass: "head"
        }, [_c('td', [_vm._v("资产名称")]), _c('td', [_vm._v("主规格")]), _c('td', [_vm._v("资产编号")])]), _vm._l(_vm.productList2, function (item, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_vm._v(_vm._s(item.productName || '-'))]), _c('td', [_vm._v(_vm._s(item.standard || '-'))]), _c('td', [_vm._v(_vm._s(item.productNo || '-'))])]);
        })], 2)])]) : _vm._e()];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };