import "core-js/modules/es.array.push.js";
import common from '@mixins/common';
import vueEsign from 'vue-esign';
import * as assetsApi from '@api/assets';
import { mapGetters } from 'vuex';
export default {
  name: 'AssetsInLogDetail',
  mixins: [common],
  components: {
    vueEsign
  },
  computed: {
    ...mapGetters('user', ['userInfo', 'projectInfo'])
  },
  data() {
    return {
      sourceId: '',
      sourceTitle: '',
      sourceType: '',
      detailList: [],
      spaceFullCode: '',
      spaceName: '',
      stockMethod: '',
      projectId: '',
      stockUser: '',
      remark: '',
      stockUserSignUrl: '',
      stockInImgList: [],
      productList2: [],
      stockMethodName: '',
      stockMethodList: [{
        value: 1,
        text: '部分入库'
      }, {
        value: 2,
        text: '全部入库'
      }]
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getData();
  },
  methods: {
    getData() {
      assetsApi.getStockInDetail(this.id).then(res => {
        let item = res.data.data;
        this.sourceId = item.sourceId;
        this.sourceTitle = item.sourceTitle;
        this.sourceType = item.sourceType;
        this.detailList = item.detailList || [];
        let productList2 = [];
        for (let product of this.detailList) {
          if (product.productNo) {
            productList2.push(product);
          }
        }
        this.spaceFullCode = item.spaceFullCode;
        this.spaceName = item.spaceName;
        this.stockMethod = item.stockMethod;
        let stock = this.stockMethodList.find(i => i.value == item.stockMethod);
        this.stockMethodName = stock ? stock.text : '';
        this.projectId = item.projectId;
        this.stockUser = item.stockUser;
        this.stockUserSignUrl = item.stockUserSignUrl;
        this.stockInImgList = item.stockInImgList ? item.stockInImgList.map(i => {
          return {
            url: i.fileUrl
          };
        }) : [];
        this.remark = item.remark;
      });
    }
  }
};